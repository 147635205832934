@import "var.conf";
@import "base.css";
@import "common.css";
/*---------------------------------
Page CSS 2018.05
CSS 担当者名 Document 2018.05
Last Update 2018.05 担当者名
---------------------------------*/

/*
トップページ
-------------------------------------*/
#Page.pageIndex,
#PageCn.pageIndex {
  #MainImg {
    .mainImg {
      &_cont {
        width: 100%;
        padding-top: 124%;
        background: url(../img/contents/top/mainimg.jpg) no-repeat center top;
        background-size: cover;
        position: relative;
        span {
          position: absolute;
          width: 8px;
          height: 47px;
          background: url(../img/contents/top/scroll.png) no-repeat left top;
          background-size: 8px auto;
          font-size: 0;
          left: 15px;
          bottom: 85px;
          &:after {
            position: absolute;
            content: '';
            width: 1px;
            height: 142px;
            background: linear-gradient(to bottom, #fff 50%, #000 50% 100%);
            top: 10.5px;
            right: -5px;
            z-index: 20;
          }
        }
      }
    }
  }
  #Main {
    .contBox {
      &_title {
        font-size: 45px;
        font-style: italic;
        color: #a98e62;
        letter-spacing: 0;
        line-height: 1;
        text-transform: uppercase;
        .jp {
          display: inline-block;
          font-family: "游ゴシック体", 'YuGothic', "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", 'Meiryo', "ＭＳ Ｐゴシック", "MS P Gothic", sans-serif;
          font-weight: 500;
          font-size: 12px;
          color: #888;
          letter-spacing: 0.05em;
          position: relative;
          &:before {
            display: inline-block;
            content: '/';
            color: #dfdfdf;
            font-style: normal;
            transform: translateY(-2px);
            margin: 0 5px 0 13px;
          }
        }
      }
      &_cont { padding: 0 15px; }
    }
    #ContBox01 {
      margin-top: -18px;
      margin-bottom: 23px;
      position: relative;
      &:before {
        position: absolute;
        content: '';
        width: calc(100% - 30px);
        height: 50px;
        background-color: #fff;
        top: -32px;
        left: 0;
      }
      .contBox {
        &_title {
          padding-left: 33px;
          margin-bottom: 4px;
          position: relative;
          z-index: 1;
        }
        &_cont {
          padding-left: 35px;
          .post { margin-bottom: 30px; }
        }
      }
    }
    #ContBox02 {
      padding-top: 30px;
      margin-bottom: 54px;
      overflow-x: hidden;
      .contBox {
        &_title {
          margin-bottom: 35px;
          padding: 0 15px 0 12px;
        }
      }
      .contSubBox {
        width: calc(100% + 60px);
        padding: 0 30px;
        margin-left: -30px;
        position: relative;
        &:before {
          position: absolute;
          content: '';
          width: 100%;
          height: calc(100% - 4.82em);
          background-color: #cdc2bc;
          top: 40px;
          left: 0;
          z-index: -1;
        }
        &_img {
          position: relative;
          margin-bottom: 43px;
          img { width: 100%; }
          .number {
            position: absolute;
            top: -13px;
            left: 28px;
            font-style: italic;
            font-size: 45px;
            color: #bd9d68;
          }
          .img_logo {
            position: absolute;
            width: 115px;
            height: 36px;
            bottom: 8px;
            left: 8px;
            img { height: inherit; }
          }
        }
        &_title {
          position: relative;
          margin-bottom: 27px;
          h3 {
            font-size: 26px;
            color: #fff;
            letter-spacing: 0.025em;
            line-height: 42px;
            text-align: justify;
            position: relative;
            z-index: 1;
          }
          span {
            font-size: 70px;
            font-weight: bold;
            color: #7f3a12;
            letter-spacing: 0;
            line-height: 60px;
            text-transform: uppercase;
            opacity: 0.1;
            position: absolute;
            top: -20px;
            right: -145px;
          }
          .serviceName {
            display: block;
            position: relative;
            left: -15px;
          }
        }
        &_cont {
          margin-bottom: 30px;
          p {
            color: #403310;
            margin-bottom: 15px;
          }
          span {
            display: block;
            font-size: 12px;
            font-weight: bold;
            color: #9b7864;
            letter-spacing: 0.05em;
          }
        }
        & + .contSubBox { margin-top: 50px; }
      }
      .contSubBox01 {
        .contSubBox_title {
          h3 { letter-spacing: -0.02em; }
          small {
            display: inline-block;
            margin-right: -17px;
          }
        }
      }
      .contSubBox02 {
        .contSubBox_title {
          span {
            top: 0;
            right: -133px;
          }
          .serviceName {
            letter-spacing: 0;
          }
        }
      }
      .contSubBox03 {
        .contSubBox_title {
          span {
            width: 432px;
            right: -242px;
          }
        }
      }
      .contSubBox04 {
        .contSubBox {
          &_img { margin-bottom: 45px; }
          &_title {
            margin-bottom: 25px;
            h3 { letter-spacing: 0.015em; }
            span {
              top: 0;
              right: -252px;
            }
            .serviceName {
              letter-spacing: -0.05em;
              word-break: keep-all;
            }
          }
          &_cont {
            p { margin-bottom: 11px; }
          }
        }
      }
    }
    #ContBox03 {
      .contBox {
        &_title {
          padding: 0 15px;
          margin-bottom: 25px;
          transform: translateX(-3px);
        }
        &_cont {
          padding: 0 0 60px;
          background-color: #f6f1e4;
        }
      }
      .contSubBox {
        padding-top: 35px;
        &_title {
          font-size: 16px;
          color: #a98e62;
          text-align: center;
          margin-bottom: 21px;
        }
      }
      .contSubBox01 {
        .logo_item {
          &_02 {
            img { transform: translate(-2px, 1px); }
            span { transform: translateY(6px); }
          }
          &_03 {
            img { transform: translate(1.5px, -1px); }
            span { transform: translateY(-6px); }
          }
          &_04 {
            img { transform: translateY(1px); }
            span { margin-top: 10px; }
          }
          &_05 {
            img { transform: translate(2px, 4px); }
            span { transform: translateY(5px); }
          }
          &_06 { padding-top: 10px; }
          &_07 {
            padding-top: 2px;
            img { transform: translate(-1px, 3px); }
          }
          &_08 {
            span { margin-top: 11px; }
          }
          &_11 { padding: 1px 0 0 1px; }
          &_13 {
            img { transform: translate(1px, 1px); }
          }
          &_20 { padding-top: 4px; }
        }
      }
      .contSubBox02 {
        margin-top: 10px;
        .logo_item {
          &_03 {
            padding-top: 1px;
            span { transform: translateY(-6px); }
          }
          &_04 {
            img { transform: translateY(-5px); }
            span { margin-top: 5px; }
          }
          &_05 {
            span { transform: translateY(-1px); }
          }
          &_06 {
            img { transform: translateX(1px); }
          }
          &_07 { padding: 9px 0 0 3px; }
        }
      }
    }
    #ContBox04 {
      padding-top: 54px;
      .contBox {
        &_title {
          margin-bottom: 24px;
          padding: 0 15px 0 9px;
          span:before { margin-left: 8px; }
        }
        &_cont { padding: 0; }
      }
      .contSubBox {
        background: url(../img/contents/bg.jpg) left top;
        background-size: 24px auto;
        &_wrap {
          background-color: #fff;
          padding: 30px 30px 23px;
          box-shadow: 0 0 8px rgba(0,0,0,0.15);
        }
        &_title {
          position: relative;
          margin-bottom: 26px;
          h3 {
            font-size: 26px;
            letter-spacing: 0.05em;
            line-height: 1;
            position: relative;
            z-index: 1;
          }
          span {
            font-size: 40px;
            font-weight: bold;
            color: #a98e62;
            letter-spacing: 0;
            line-height: 1;
            text-transform: uppercase;
            position: absolute;
            top: 8px;
            left: 30px;
            opacity: 0.08;
          }
        }
        &_cont {
          h4 {
            font-size: 18px;
            font-weight: bold;
            color: #a98e62;
            letter-spacing: 0.05em;
            line-height: 32px;
            margin-bottom: 3px;
          }
          p {
            strong {
              font-weight: bold;
              font-size: 16px;
            }
            & + h4 { margin-top: 11px; }
          }
          img { width: 100%; }
          table {
            width: 100%;
            table-layout: fixed;
            border-bottom: 1px solid #e5e5e5;
            tr {
              border-top: 1px solid #e5e5e5;
              &:nth-of-type(2) {
                td { padding: 13px 0 11px; }
              }
              &:nth-of-type(4) {
                th { padding: 15px 15px 8px 0; }
                td {
                  vertical-align: middle;
                  padding-top: 20px;
                }
              }
            }
            th {
              width: 29.6%;
              font-size: 14px;
              color: #a98e62;
              line-height: 24px;
              vertical-align: middle;
            }
            td {
              line-height: 26px;
              padding: 14px 0;
            }
          }
          .map {
            border: 1px solid #e5e5e5;
            width: 100%;
            height: 244px;
            margin-bottom: 12px;
            iframe {
              width: 100%;
              height: 100%;
            }
          }
          .mapLink {
            display: inline-block;
            font-size: 14px;
            color: #888;
            letter-spacing: 0.03em;
            line-height: 24px;
            text-decoration: none;
            padding-left: 20px;
            background: url(../img/contents/icon_01.png) no-repeat left top 2px;
            background-size: 16px auto;
          }
        }
      }
      .contSubBox01 {
        padding: 40px 0 10px;
        .contSubBox {
          &_cont {
            p:last-of-type { letter-spacing: 0.03em; }
          }
        }
      }
      .contSubBox02 {
        padding: 10px 0 40px;
        .contSubBox {
          &_wrap { padding-right: 28px; }
          &_title { margin-bottom: 33px; }
          &_cont {
            img {
              display: block;
              max-width: 285px;
              margin: 0 auto 12px;
            }
            span {
              display: block;
              font-size: 12px;
              color: #888;
              letter-spacing: 0.05em;
              line-height: 21px;
              text-align: right;
              margin-top: 14px;
            }
          }
        }
      }
      .contSubBox03 {
        padding-bottom: 41px;
        background-color: #f8f8f8;
        background-image: none;
        .contSubBox {
          &_box {
            padding-top: 50px;
          }
          &_box02 {
            padding-top: 38px;
            .contSubBox_cont { text-align: right; }
          }
          &_title { margin-bottom: 41px; }
        }
      }
    }
    #ContBox05 {
      background: url(../img/contents/bg.jpg) left top;
      background-size: 24px auto;
      padding-top: 44px;
      padding-bottom: 50px;
      position: relative;
      .contBox {
        &_title {
          color: #fff;
          margin-bottom: 24px;
          transform: translateX(-3px);
          span {
            color: #fff;
            &:before { margin-left: 7px; }
          }
        }
        &_cont { padding: 0; }
      }
      .contSubBox {
          background-color: #fff;
          box-shadow: 0 0 8px rgba(0,0,0,0.15);
        .click {
          line-height: 27px;
          background: url(../img/contents/icon_02.png) no-repeat right 15px center;
          background-size: 16px auto;
          font-size: 16px;
          padding: 16px 40px 17px 13px;
          &.active { background-image: url(../img/contents/icon_03.png); }
        }
        &_cont {
          padding: 0 15px;
          table {
            width: 100%;
            table-layout: fixed;
            margin-bottom: 26px;
            border-bottom: 1px solid #e5e5e5;
            tr { border-top: 1px solid #e5e5e5; }
            th {
              width: 30.5%;
              font-size: 14px;
              font-weight: bold;
              color: #a98e62;
              letter-spacing: 0.05em;
              line-height: 24px;
              padding-left: 15px;
            }
            td { padding: 12px 15px 13px; }
            &.col {
              border-bottom-width: 0;
              margin-bottom: 0;
              th,td {
                display: block;
                width: 100%;
              }
              th { padding: 15px 15px 0; }
              td {
                padding: 2px 15px 12px;
                letter-spacing: 0.03em;
                .note:first-of-type { margin-top: -2px; }
              }
            }
          }
          img { width: 100%; }
        }
        &_item {
          padding: 0 11px 36px 15px;
          &_title {
            position: relative;
            line-height: 52px;
            margin-bottom: 16px;
            h3 {
              font-size: 26px;
              color: #7a5a26;
              letter-spacing: -0.05em;
              position: relative;
              z-index: 1;
              small {
                font-size: 18px;
                letter-spacing: 0.05em;
                margin-left: 5px;
              }
            }
            span {
              position: absolute;
              // z-index: -1;
              font-size: 40px;
              font-weight: 900;
              color: #a98e62;
              letter-spacing: 0;
              text-transform: uppercase;
              opacity: 0.08;
              top: 18px;
              left: 20px;
            }
          }
          &_cont {
            h4 {
              font-weight: bold;
              color: #a98e62;
              margin-top: 18px;
              .label {
                display: inline-block;
                width: 35px;
                height: 18px;
                font-size: 12px;
                font-weight: bold;
                color: #fff;
                letter-spacing: 0.05em;
                line-height: 18px;
                text-align: center;
                background-color: #7f3a12;
                transform: translate(9px, -1px);
              }
              &:first-of-type { margin-top: 0; }
            }
            h5 {
              font-weight: bold;
              margin-top: 17px;
              small {
                font-size: 12px;
                font-weight: normal;
              }
              & + a { margin-top: -4px; }
            }
            p {
              & + .note { margin-top: -3px; }
            }
            a { display: inline-block; }
            ul {
              & + .note { margin-top: -2px; }
            }
          }
          & + .contSubBox_item {
            border-top: 1px solid #e5e5e5;
            padding-top: 26px;
            .contSubBox_item_title { margin-bottom: 8px; }
          }
          &:first-of-type {
            padding-bottom: 33px;
          }
          &:last-of-type {
            .contSubBox_item {
              &_cont {
                p {
                  span { margin-top: -2px; }
                  & + p { margin-top: 10px; }
                }
              }
            }
          }
        }
      }
      .contSubBox01 {
        margin-bottom: 15px;
        .contSubBox {
          &_cont {
            table.col {
              transform: translateY(-2px);
              tr {
                &:nth-of-type(2) {
                  th { padding-top: 17px; }
                  td { padding: 1px 15px 16px; }
                }
                &:nth-of-type(3) {
                  td { padding: 1px 15px 14px; }
                }
                &:nth-of-type(4) {
                  td {
                    padding-bottom: 11px;
                    .note {
                      letter-spacing: -0.01em;
                      margin-top: -1px;
                    }
                  }
                }
              }
            }
          }
          &_item {
            &:nth-of-type(1) {
              padding-bottom: 34px;
              .contSubBox_item_cont {
                .note:first-of-type { margin-top: -1px; }
                h4:last-of-type { margin-top: 15px; }
              }
            }
            &:nth-of-type(2) {
              .contSubBox_item {
                &_title { margin-bottom: 7px; }
                &_cont {
                  .note { margin-top: -1px; }
                }
              }
            }
            &:nth-of-type(3) { padding-bottom: 31px; }
            &:nth-of-type(4) {
              padding-top: 27px;
              padding-bottom: 31px;
              .contSubBox_item_title { margin-bottom: 10px; }
            }
            &:nth-of-type(5) {
              padding-bottom: 33px;
              .contSubBox_item_title { margin-bottom: 10px; }
            }
            &:last-of-type {
              padding-top: 27px;
              padding-bottom: 24px;
              .contSubBox_item {
                &_title { margin-bottom: 10px; }
              }
            }
          }
        }
      }
      .contSubBox02 {
        .contSubBox {
          &_cont {
            margin-top: -2px;
            table {
              &.col {
                tr {
                  &:nth-of-type(2) td { padding-bottom: 18px; }
                  &:nth-of-type(3) td { padding: 1px 15px 14px; }
                  &:nth-of-type(4) td { padding-bottom: 13px; }
                }
              }
              tr {
                &:first-of-type td { padding-bottom: 12px; }
              }
            }
          }
          &_item {
            &:first-of-type {
              .contSubBox_item {
                &_cont {
                  ul {
                    margin-top: 9px;
                    li { line-height: 27px; }
                  }
                }
              }
            }
            &:nth-of-type(2) {
              padding-bottom: 33px;
              .contSubBox_item_title { margin-bottom: 7px; }
            }
            &:nth-of-type(3) {
              padding-bottom: 32px;
              .contSubBox_item_title { margin-bottom: 7px; }
            }
            &:nth-of-type(4) {
              padding-bottom: 33px;
              .contSubBox_item_title { margin-bottom: 10px; }
            }
            &:nth-of-type(5) {
              padding-bottom: 33px;
              .contSubBox_item_title { margin-bottom: 9px; }
            }
            &:last-of-type {
              padding-bottom: 23px;
              .contSubBox_item {
                &_title { margin-bottom: 10px; }
                &_cont {
                  .note:first-of-type { margin-top: -1px; }
                  h4:nth-of-type(2) { margin-top: 16px; }
                  h5:last-of-type {
                    margin-top: 16px;
                    margin-bottom: -2px;
                  }
                }
              }
            }
          }
        }
      }
    }
    #ContBox06 {
      background-color: #f6f1e4;
      padding-top: 45px;
      padding-bottom: 50px;
      .contBox {
        &_title {
          transform: translateX(-6px);
          margin-bottom: 27px;
          span:before { margin-left: 8px; }
        }
        &_cont {
          padding: 0;
          .form {
            margin-top: 32px;
            padding-bottom: 40px;
            &_cont {
              tr {
                &:first-of-type {
                  td {
                    margin-top: 7px;
                    padding-bottom: 18px;
                  }
                }
                &:nth-of-type(2) {
                  th:before {
                    content: '任意';
                    background-color: #a4a4a4;
                  }
                }
              }
              th {
                padding-top: 16px;
              }
              td {
                margin-top: 9px;
                label + label { margin-top: 9px; }
              }
            }
          }
          .policy {
            padding-top: 41px;
            position: relative;
            &:before {
              position: absolute;
              content: '';
              width: calc(100% + 30px);
              height: 1px;
              background-color: #e9e0c9;
              top: 0px;
              left: -15px;
            }
            &_wrap { background-color: #fff; }
            &_title {
              padding: 17px 15px 15px 13px;
              background: url(../img/contents/icon_02.png) no-repeat right 15px top 22px;
              background-size: 16px auto;
              font-size: 16px;
              &.active { background-image: url(../img/contents/icon_03.png); }
            }
            &_cont {
              padding: 10px 15px 22px;
              position: relative;
              &:before {
                position: absolute;
                content: '';
                width: calc(100% - 30px);
                height: 1px;
                background-color: #e5e5e5;
                top: -2px;
                left: 50%;
                transform: translateX(-50%);
              }
            }
            &_item {
              margin-top: 32px;
              h4 {
                font-weight: bold;
                color: #a98e62;
              }
              p + p { margin-top: 16px; }
              ul { margin-top: 16px; }
            }
          }
        }
      }
    }
  }
  #FooterInfo { display: none; }
}

/*
トップページ 確認画面
-------------------------------------*/
#PageContact.pageConfirm {
  #Main {
    .form {
      tr:nth-of-type(2) {
        th:before {
          content: '任意';
          background-color: #a4a4a4;
        }
      }
    }
  }
}

/*
ヒューマジック
-------------------------------------*/
#PageHumasic,
#PageCnHumasic {
  #Footer {
    background-color: #7c253c;
    .footer {
      &_box {
        border-color: #914055;
        .banner {
          background-color: #eecdd7;
          .jp { color: #6f2532; }
          .en {
            color: #e7c0cc;
            opacity: 1;
          }
        }
      }
      &_box01 { padding-top: 40px; }
      &_box02, &_copy {
        small, a { color: #b46f81; }
      }
    }
  }
}
#PageHumasic.pageIndex,
#PageCnHumasic.pageIndex {
  #MainImg {
    .mainImg_cont {
      background-image: url(../img/contents/humasic/mainimg.jpg);
      img { width: 100%; }
      .logo {
        position: absolute;
        top: -60px;
        left: 0;
        width: 211px;
        z-index: 51;
      }
      .title {
        position: absolute;
        bottom: -40px;
        left: 50%;
        transform: translateX(-50%);
        width: 310px;
        text-align: center;
        span {
          display: inline-block;
          font-size: 18px;
          font-weight: bold;
          color: #fff;
          letter-spacing: 0.3em;
          line-height: 2;
        }
        h2 {
          width: 100%;
          height: 100px;
          background: url(../img/contents/humasic/title.png) no-repeat left top;
          background-size: 100%;
          text-indent: -9999px;
          margin-top: -20px;
        }
      }
    }
  }
  #Main {
    .contBox {
      padding-top: 37px;
      &_title {
        position: relative;
        .en {
          display: block;
          font-size: 10px;
          color: #8ec31f;
          letter-spacing: 0.3em;
          line-height: 1.2;
          text-align: center;
          text-transform: uppercase;
          padding-left: 0.3em;
          margin-bottom: 7px;
        }
        h3 {
          font-size: 26px;
          letter-spacing: 0.2em;
          line-height: 39px;
          text-align: center;
          padding-left: 0.3em;
          position: relative;
          z-index: 1;
        }
        .icon {
          position: absolute;
          width: 100%;
          max-width: 345px;
          height: 100%;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          &:before, &:after {
            position: absolute;
            content: '';
            background-position: left top;
            background-repeat: no-repeat;
            background-size: 100% auto;
          }
          &:after { transform: scale(-1, 1); }
        }
        &.party .icon {
          &:before, &:after {
            width: 74px;
            height: 62px;
            background-image: url(../img/contents/humasic/illus_01.png);
          }
          &:before {
            top: 10px;
            left: 34px;
          }
          &:after {
            top: 10px;
            right: 32px;
          }
        }
        &.fouricon .icon {
          &:before, &:after {
            width: 72px;
            height: 32px;
            background-image: url(../img/contents/humasic/illus_02.png);
          }
          &:before {
            left: 36px;
            bottom: 8px;
          }
          &:after {
            right: 34px;
            bottom: 8px;
          }
        }
        &.twoicon .icon {
          &:before {
            width: 32px;
            height: 30px;
            background-image: url(../img/contents/humasic/illus_03.png);
            bottom: 5px;
            left: 49px;
          }
          &:after {
            width: 29px;
            height: 22px;
            background-image: url(../img/contents/humasic/illus_04.png);
            bottom: 4px;
            right: 48px;
            transform: unset;
          }
        }
      }
      &_cont {
        figure {
          text-align: center;
          img { width: 100%; }
        }
      }
    }
    #ContBox01 {
      padding-top: 83px;
      padding-bottom: 15px;
      .contBox {
        &_title {
          margin-bottom: 24px;
          h3 {
            color: #ea617c;
            line-height: 36px;
          }
        }
        &_cont {
          dl {
            margin-bottom: 15px;
            dt {
              font-size: 18px;
              font-weight: bold;
              color: #fff;
              letter-spacing: 0.05em;
              line-height: 32px;
              background: #ea617c url(../img/contents/icon_04.png) no-repeat left 20px top 15px;
              background-size: 21px auto;
              padding: 11px 15px 7px 51px;
            }
            dd {
              background-color: #f6f6f6;
              font-size: 14px;
              letter-spacing: 0.05em;
              line-height: 28px;
              text-align: justify;
              padding: 7px 20px;
            }
          }
        }
      }
    }
    #ContBox02 {
      background: url(../img/contents/bg_01.jpg) left top;
      background-size: 24px auto;
      padding: 30px 0;
      .contBox_cont {
        background-color: #fff;
        box-shadow: 0 0 10px rgba(0,0,0,0.1);
        padding: 19px 20px 23px;
        h3 {
          font-size: 18px;
          letter-spacing: 0.2em;
          line-height: 39px;
          text-align: center;
          padding-left: 0.2em;
          padding-bottom: 8px;
          margin-bottom: 16px;
          border-bottom: 1px solid #f4f4f4;
          small {
            display: inline-block;
            font-size: 26px;
            color: #ff8a00;
            letter-spacing: 0.2em;
          }
        }
        figure {
          text-align: center;
          img { max-width: 305px; }
        }
        p {
          font-size: 14px;
          letter-spacing: 0.05em;
          line-height: 28px;
          text-align: center;
          padding-left: 0.05em;
          margin-top: 17px;
          small {
            font-weight: bold;
            color: #ea617c;
          }
        }
      }
    }
    #ContBox03 {
      .contBox {
        &_title { margin-bottom: 21px; }
        &_cont {
          .imgBox {
            margin-bottom: 9px;
            &_cont {
              span {
                display: block;
                font-size: 12px;
                color: #888;
                letter-spacing: 0.05em;
                line-height: 32px;
                margin-top: 5px;
              }
            }
            .popBox {
              display: none;
              position: fixed;
              width: 100%;
              height: 100vh;
              top: 0;
              left: 0;
              background-color: rgba(0,0,0,0.7);
              z-index: 51;
              figure {
                position: absolute;
                top: 50%;
                left: 50%;
                width: calc(100% - 30px);
                transform: translate(-50%, -50%);
              }
            }
          }
          dl {
            border: 1px solid #ea617c;
            dt {
              font-size: 18px;
              font-weight: bold;
              color: #ea617c;
              letter-spacing: 0.05em;
              padding: 13px 19px 12px;
              border-bottom: 1px solid #ea617c;
            }
            dd {
              font-size: 14px;
              letter-spacing: 0.03em;
              line-height: 28px;
              padding: 11px 19px 13px;
              p {
                & + p { margin-top: 14px; }
              }
              small {
                font-weight: bold;
                color: #ea617c;
              }
            }
          }
        }
      }
    }
    #ContBox04 {
      .contBox {
        &_title {
          padding: 0 15px;
          margin-bottom: 21px;
        }
        &_cont {
          background: url(../img/contents/bg_02.jpg) left top;
          background-size: 24px auto;
          padding: 30px 0;
          .contSubBox {
            background-color: #fff;
            box-shadow: 0 0 10px rgba(0,0,0,0.1);
            padding: 27px 20px 22px;
            .title {
              font-size: 18px;
              letter-spacing: 0.11em;
              line-height: 27px;
              padding-left: 15px;
              margin-bottom: 26px;
              position: relative;
              small { color: #ff8a00; }
              &:before {
                position: absolute;
                content: '';
                width: 4px;
                height: 18px;
                background-color: #8ec31f;
                top: 4px;
                left: 0;
              }
            }
            .cont {
              padding-top: 20px;
              border-top: 1px solid #f4f4f4;
              p {
                font-size: 14px;
                letter-spacing: 0.05em;
                line-height: 28px;
                margin-top: 11px;
              }
              figure img { max-width: 610px; }
            }
            & + .contSubBox { margin-top: 10px; }
          }
        }
      }
    }
    #ContBox05 {
      .contBox {
        &_title { margin-bottom: 21px; }
      }
    }
    #ContBox06 {
      .contBox {
        &_title { margin-bottom: 22px; }
        &_cont {
          table {
            tr {
              background-color: #f6f6f6;
              &:last-of-type td { padding-bottom: 14px; }
            }
            th {
              background-color: #ea617c;
              font-size: 18px;
              color: #fff;
              letter-spacing: 0.05em;
              padding: 10px 20px 8px;
            }
            td {
              padding: 11px 20px 10px;
              border-bottom: 1px solid #fff;
              h4, p {
                font-size: 14px;
                letter-spacing: 0.05em;
                line-height: 21px;
              }
              h4 {
                font-weight: bold;
                color: #ea617c;
                margin-bottom: 1px;
              }
              small {
                font-size: 12px;
                &.gray { color: #888; }
              }
            }
          }
        }
      }
    }
    #ContBox07 {
      .contBox {
        &_title {
          padding: 0 15px;
          margin-bottom: 22px;
          .icon {
            &:before { left: -0.09375em; }
            &:after { right: -4px; }
          }
        }
        &_cont {
          background: url(../img/contents/bg_01.jpg) left top;
          background-size: 24px auto;
          padding: 34px 0 30px;
          .contSubBox {
            background-color: #fff;
            box-shadow: 0 0 10px rgba(0,0,0,0.1);
            padding: 13px 30px 22px;
            .title {
              margin-bottom: 19px;
              h4 {
                font-size: 18px;
                letter-spacing: 0.05em;
                line-height: 32px;
                margin-bottom: 2px;
              }
              span {
                display: block;
                font-size: 12px;
                font-weight: 400;
                color: #fa6b90;
                letter-spacing: 0.2em;
                line-height: 1;
              }
            }
            .cont {
              p {
                font-size: 14px;
                letter-spacing: 0.05em;
                line-height: 28px;
                margin-top: 11px;
              }
              figure img {
                max-width: 285px;
                margin: 0 auto;
              }
            }
            & + .contSubBox { margin-top: 10px; }
          }
          .contSubBox01 {
            .cont {
              figure img {max-width: 285px; }
            }
          }
          .contSubBox03 { padding-bottom: 14px; }
          .contSubBox05, .contSubBox06,
          .contSubBox07, .contSubBox08 { padding-top: 14px; }
          .contSubBox08 {
            .cont p { letter-spacing: 0.04em; }
          }
        }
      }
    }
    #ContBox08 {
      background-color: #f6f6f6;
      padding-bottom: 40px;
      .contBox {
        &_title {
          margin-bottom: 21px;
          .icon {
            &:before { left: 68px; }
            &:after { right: 70px; }
          }
        }
        &_cont {
          .logo_item {
            border-color: #f6f6f6;
            &:nth-of-type(2n) { border-color: #f6f6f6; }
            &_02 {
              img { transform: translate(-2px, 1px); }
              span { transform: translateY(6px); }
            }
            &_03 {
              img { transform: translate(1.5px, -1px); }
              span { transform: translateY(-6px); }
            }
            &_04 {
              img { transform: translateY(1px); }
              span { margin-top: 10px; }
            }
            &_05 {
              img { transform: translate(2px, 4px); }
              span { transform: translateY(5px); }
            }
            &_06 { padding-top: 10px; }
            &_07 {
              padding-top: 2px;
              img { transform: translate(-1px, 3px); }
            }
            &_08 {
              span { margin-top: 11px; }
            }
            &_11 { padding: 1px 0 0 1px; }
            &_13 {
              img { transform: translate(1px, 1px); }
            }
            &_20 { padding-top: 4px; }
          }
        }
      }
    }
    #ContBox09 {
      .contBox {
        &_title {
          margin-bottom: 21px;
          .icon {
            &:before { left: 22px; }
            &:after { right: 22px; }
          }
        }
      }
    }
    #ContBox10 {
      .contBox {
        &_title {
          padding: 0 15px;
          margin-bottom: 22px;
          .icon {
            &:before { left: 22px; }
            &:after { right: 22px; }
          }
        }
        &_cont {
          background: url(../img/contents/bg_02.jpg) left top;
          background-size: 24px auto;
          padding: 43px 0 30px;
          .contSubBox {
            background-color: #fff;
            box-shadow: 0 0 10px rgba(0,0,0,0.1);
            border-top: 4px solid #dfdfdf;
            .cont {
              position: relative;
              padding: 26px 20px 20px;
              .label {
                position: absolute;
                width: 120px;
                background-color: #8ec31f;
                font-size: 12px;
                font-weight: bold;
                color: #fff;
                letter-spacing: 0.05em;
                line-height: 16px;
                text-align: center;
                padding: 8px 0 6px 0.05em;
                top: -17px;
                left: 50%;
                transform: translateX(-50%);
              }
              h4 {
                font-size: 18px;
                font-weight: bold;
                color: #ff8a00;
                letter-spacing: 0.05em;
                line-height: 32px;
              }
              p {
                font-size: 14px;
                letter-spacing: 0.05em;
                line-height: 28px;
                margin-top: -1.5px;
                & + figure { margin-top: 13px; }
              }
              figure img { max-width: 610px; }
            }
            & + .contSubBox { margin-top: 33px; }
          }
          .contSubBox01 {
            .cont { padding-bottom: 18px; }
          }
          .contSubBox02 {
            .cont {
              padding-bottom: 13px;
            }
          }
          .contSubBox04 {
            .cont { padding-bottom: 12px; }
          }
        }
      }
    }
    #ContBox11 {
      padding-top: 32px;
      padding-bottom: 25px;
      .contBox {
        &_title {
          margin-bottom: 24px;
          h3 {
            color: #f08200;
            line-height: 36px;
            padding-left: 0.4em;
          }
        }
        &_cont {
          dl {
            margin-bottom: 15px;
            dt {
              font-size: 18px;
              font-weight: bold;
              color: #fff;
              letter-spacing: 0.05em;
              line-height: 32px;
              background: #f08200 url(../img/contents/icon_04.png) no-repeat left 20px top 15px;
              background-size: 21px auto;
              padding: 11px 15px 7px 51px;
            }
            dd {
              background-color: #f6f6f6;
              font-size: 14px;
              letter-spacing: 0.05em;
              line-height: 28px;
              text-align: justify;
              padding: 7px 20px;
            }
          }
        }
      }
    }
    #ContBox12 {
      background-color: #ea617c;
      padding-bottom: 40px;
      .contBox {
        &_title {
          margin-bottom: 13px;
          h3 {
            color: #fff;
          }
          .en { color: #fff; }
          .icon {
            &:before { left: 38px; }
            &:after { right: 40px; }
          }
        }
        &_cont {
          p {
            font-size: 14px;
            color: #fff;
            letter-spacing: 0.05em;
            line-height: 28px;
          }
          .form {
            margin-top: 12px;
            background-color: #fff;
            padding: 10px 20px 30px;
            tr {
              border-top-width: 0;
              border-bottom: 1px solid #f4f4f4;
              &:first-of-type {
                td {
                  margin-top: 5px;
                  padding-bottom: 17px;
                }
              }
              &:nth-of-type(2) {
                th:before {
                  content: '任意';
                  background-color: #a4a4a4;
                }
              }
              &:last-of-type { border-bottom-width: 0; }
            }
            th {
              font-weight: bold;
              color: #21ade5;
              &:before { background-color: #fa6b90; }
            }
            td {
              input, textarea { border-color: #dfdfdf;}
              textarea { min-height: 210px; }
              label {
                & + label { margin-top: 9px; }
              }
              .radio {
                span {
                  background-image: url(../img/contents/radio_03.png);
                  &.checked { background-image: url(../img/contents/radio_04.png); }
                }
              }
            }
            &_button {
              background-color: #fa6b90;
              margin-top: 5px;
            }
          }
        }
      }
    }
  }
  #FooterInfo {
    background-color: #601327;
    .telBox {
      background-image: url(../img/contents/humasic/telicon.png);
      span {
        color: #dfb36c;
        opacity: 0.5;
      }
      a { color: #dfb36c; }
    }
    .formBox {
      a {
        background-color: #dfb36c;
        background-image: url(../img/contents/arrow_01.png);
        color: #601327;
      }
    }
  }

  &.active {
    #MainImg {
      .mainImg_cont .logo { z-index: unset; }
    }
  }
}

/*
ヒューマジック 確認画面
-------------------------------------*/
#PageHumasic.pageConfirm,
#PageCnHumasic.pageConfirm {
  #Main {
    .form {
      &_cont {
        tr {
          &:nth-of-type(2) {
            th:before {
              content: '任意';
              background-color: #a4a4a4;
            }
          }
        }
        th {
          font-weight: bold;
          color: #21ade5;
          &:before { background-color: #fa6b90; }
        }
      }
      &_button { background-color: #fa6b90; }
      &_return { color: #914055; }
    }
  }
}

/*
ヒューマジック 完了画面
-------------------------------------*/
#PageHumasic.pageThanks,
#PageCnHumasic.pageThanks {
  #Main {
    .homeBtn { background-color: #fa6b90; }
  }
}

/*
お知らせ
-------------------------------------*/
#PageInformation.pageIndex {
  #Main {
    padding-top: 53px;
    padding-bottom: 60px;
    .innerBasic { padding: 0 30px; }
    .moreBtn { margin-top: 40px; }
  }
}

/*
お知らせ 記事詳細ページ
-------------------------------------*/
#PageInformation.pageEntry {
  #Main {
    padding-top: 53px;
    padding-bottom: 60px;
    .innerBasic { padding: 0 30px; }
    .contBox {
      &_title { margin-bottom: 23px; }
      &_cont {
        .post {
          border-bottom: 1px solid #ededed;
          &_head {
            padding-bottom: 23px;
            border-bottom: 1px solid #ededed;
            .date {
              display: block;
              font-size: 14px;
              font-style: italic;
              color: #9b7864;
              letter-spacing: 0.2em;
              margin-bottom: 4.5px;
            }
            .title {
              font-size: 18px;
              letter-spacing: 0.05em;
              line-height: 29px;
              text-align: justify;
            }
          }
          &_cont {
            padding-top: 30px;
            padding-bottom: 21px;
            p { margin-top: 15px; }
          }
        }
        .pageNavi {
          margin-top: 40px;
          ul {
            position: relative;
            li {
              padding-left: 0;
              width: 38.098%;
              max-width: 120px;
              height: 35px;
              margin: 0 auto;
              a {
                display: block;
                background-color: #7f3a12;
                font-size: 12px;
                color: #fff;
                letter-spacing: 0.05em;
                line-height: 35px;
                text-align: center;
                text-decoration: none;
                span:after {
                  display: inline-block;
                  content: '';
                  width: 10px;
                  height: 9px;
                  background: url(../img/contents/arrow_return.png) no-repeat left top;
                  background-size: 100% auto;
                  margin-left: 4px;
                }
              }
              &.prev {
                position: absolute;
                top: 0;
                left: 0;
                width: 27.778%;
                max-width: 86px;
                a span {
                  &:after { display: none; }
                  &:before {
                    display: inline-block;
                    content: '';
                    width: 6px;
                    height: 10px;
                    background: url(../img/contents/arrow_prev.png) no-repeat left top;
                    background-size: 100% auto;
                    margin-right: 7px;
                    transform: translateY(1px);
                  }
                }
              }
              &.next {
                position: absolute;
                top: 0;
                right: 0;
                width: 27.778%;
                max-width: 86px;
                a span:after {
                  width: 6px;
                  height: 10px;
                  background-image: url(../img/contents/arrow_next.png);
                  transform: translateY(1px);
                }
              }
              &:before { display: none; }
            }
          }
        }
      }
    }
  }
}

/*
サウンドクリエーター
-------------------------------------*/
#PageSound,
#PageCnSound {
  #Footer {
    background-color: #14572f;
    .footer {
      &_box {
        border-color: #236f42;
        .banner {
          background-color: #abd8b8;
          .jp { color: #0d7c3a; }
          .en {
            color: #99cfaf;
            opacity: 1;
          }
        }
      }
      &_box01 { padding-top: 60px; }
      &_box02, &_copy {
        small, a { color: #5d9774; }
      }
    }
  }
  #FooterInfo {
    background-color: #0c4724;
    .telBox {
      background-image: url(../img/contents/sound/telicon.png);
      span { opacity: 0.5; }
    }
    .formBox a {
      background-image: url(../img/contents/arrow_03.png);
      color: #0c4724;
    }
  }
}
#PageSound.pageIndex,
#PageCnSound.pageIndex {
  #MainImg {
    .mainImg_cont {
      background-image: url(../img/contents/sound/mainimg.jpg);
      .title {
        position: absolute;
        left: 50%;
        bottom: 14px;
        transform: translateX(-50%);
        word-break: keep-all;
        span {
          display: inline-block;
          width: 100%;
          height: 48px;
          background: url(../img/contents/sound/text_01.png) no-repeat left 24px center;
          background-size: auto 48px;
          font-size: 0;
          transform: translateY(-1px);
        }
        h2 {
          font-size: 32px;
          font-weight: bold;
          color: #fff;
          letter-spacing: 0.05em;
          line-height: 55px;
          margin-top: -7px;
        }
      }
    }
  }
  #Main {
    .contBox {
      p {
        & + p { margin-top: 10px; }
      }
      figure {
        text-align: center;
        img { width: 100%; }
      }
      ul {
        li {
          &:before { background-color: #0d7c3a; }
        }
      }
      a[target="_blank"] {
        display: inline-block;
        color: #d71933;
        padding-right: 15px;
        background: url(../img/contents/link_01.png) no-repeat right top 6px;
        background-size: 13px auto;
      }
      .red {
        color: #d71933;
        font-weight: bold;
      }
      .note {
        font-size: 12px;
        color: #888;
      }
      .stepBox {
        background-color: #f8f8f8;
        border-top: 4px solid #e9e8e8;
        margin-top: 35px;
        .cont {
          position: relative;
          padding: 23px 20px 13px;
          .label {
            position: absolute;
            width: 120px;
            height: 30px;
            background-color: #ff9000;
            font-size: 12px;
            font-weight: bold;
            color: #fff;
            letter-spacing: 0.05em;
            line-height: 30px;
            text-align: center;
            top: -19px;
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }

      &_title {
        text-align: center;
        .en {
          display: block;
          font-size: 10px;
          color: #ff9d00;
          letter-spacing: 0.3em;
          line-height: 16px;
          text-transform: uppercase;
          background: url(../img/contents/sound/icon_02.png) no-repeat center top;
          background-size: 26px auto;
          padding: 25px 0 0 0.3em;
        }
        h3 {
          font-size: 26px;
          color: #0d7c3a;
          letter-spacing: 0.05em;
          line-height: 46px;
          padding-left: 0.05em;
        }
      }
    }

    #ContBox01 {
      background-color: #3fad34;
      padding: 40px 0;
      .contSubBox {
        &_title {
          text-align: center;
          margin-bottom: 26px;
          span {
            display: block;
            font-size: 10px;
            color: #ffff00;
            letter-spacing: 0.3em;
            line-height: 16px;
            text-transform: uppercase;
            background: url(../img/contents/sound/icon_01.png) no-repeat center top;
            background-size: 40px auto;
            padding: 25px 0 0 0.3em;
          }
          h3 {
            font-size: 26px;
            color: #fff;
            letter-spacing: 0.05em;
            line-height: 37px;
            margin-top: 10px;
          }
        }
        &_cont {
          background-color: #fff;
          box-shadow: 0 2px 8px rgba(0,0,0,0.15);
          padding: 20px 20px 23px;
          figure, .video { margin-bottom: 12px; }
          figure {
            img { max-width: 610px; }
          }
          p {
            &.note { margin-top: 12px; }
          }
          .stepBox {
            &:first-of-type { margin-top: 39px; }
          }
          dl {
            margin-top: 30px;
            dt {
              background-color: #3da833;
              font-size: 16px;
              color: #fff;
              padding: 8px 20px 5px;
            }
            dd {
              background-color: #f8f8f8;
              padding: 12px 20px 13px;
              ul li { padding-left: 12px; }
            }
          }
        }
        & + .contSubBox { margin-top: 40px; }
      }
      .contSubBox02 {
        .contSubBox {
          &_title { margin-bottom: 22px; }
          &_cont {
            padding-bottom: 19px;
            .stepBox { margin-top: 34px; }
          }
        }
      }
      .contSubBox03 {
        .contSubBox {
          &_title {
            margin-bottom: 23px;
            h3 { letter-spacing: 0.02em; }
          }
          &_cont {
            padding-bottom: 20px;
            .stepBox {
              &:nth-of-type(4) { margin-top: 33px; }
            }
            dl { margin-top: 28px; }
          }
        }
      }
    }
    #ContBox02 {
      background: url(../img/contents/bg_01.jpg) left top;
      background-size: 24px auto;
      padding: 40px 0;
      .contBox_cont {
        background-color: #fff;
        box-shadow: 0 2px 8px rgba(0,0,0,0.15);
        padding: 30px 20px 21px;
        .title {
          text-align: center;
          span {
            display: block;
            height: 48px;
            background: url(../img/contents/sound/text_02.png) no-repeat center top;
            background-size: auto 48px;
            font-size: 0;
          }
          h3 {
            font-size: 26px;
            color: #0d7c3a;
            letter-spacing: 0.05em;
            line-height: 36px;
          }
        }
        .content {
          margin-top: 12px;
          padding-top: 11px;
          border-top: 1px solid #f4f4f4;
        }
      }
    }
    #ContBox03 {
      padding-top: 40px;
      .contBox {
        &_title {
          margin-bottom: 22px;
          en { margin-bottom: 2px; }
        }
      }
    }
    #ContBox04 {
      padding-top: 40px;
      .contBox {
        &_title {
          h3 {
            font-size: 28px;
            margin-top: 7px;
          }
        }
        &_cont {
          .table {
            margin-top: 30px;
            h4 {
              background-color: #3fad34;
              font-size: 18px;
              font-weight: bold;
              color: #fff;
              letter-spacing: 0.05em;
              line-height: 32px;
              padding: 11px 18px 7px;
              margin-bottom: 10px;
            }
            table {
              tr {
                background-color: #f6f6f6;
                & + tr { border-top: 1px solid #fff; }
              }
              th, td {
                display: block;
                line-height: 22px;
                padding: 0 20px 0 20px;
              }
              th { padding-top: 11px; }
              td {
                padding-bottom: 11px;
                .gray {
                  font-size: 12px;
                  color: #888;
                }
              }
              & + table { margin-top: 10px; }
              &:first-of-type th { color: #d71933; }
              &:nth-of-type(1) {
                tr {
                  &:nth-of-type(1),&:nth-of-type(2),&:nth-of-type(3) {
                    td { padding-bottom: 9px; }
                  }
                  &:nth-of-type(4) td { letter-spacing: 0; }
                }
              }
              &:nth-of-type(2) {
                tr {
                  &:nth-of-type(2) td { padding-bottom: 10px; }
                }
                th { color: #ff9000; }
              }
            }
          }
          .table01 {
            margin-top: 18px;
            table:nth-of-type(1) {
              tr:nth-of-type(4) td { padding-bottom: 10px; }
            }
          }
          .table02 {
            table:nth-of-type(2) {
              tr:nth-of-type(5) td { padding-bottom: 10px; }
            }
          }
        }
      }
    }
    #ContBox05 {
      padding-top: 40px;
      .contBox {
        &_title {
          margin-bottom: 17px;
          h3 { margin-top: 7px; }
        }
        &_cont {
          background: url(../img/contents/bg_03.jpg) left top;
          background-size: 24px auto;
          padding: 40px 0;
          .logo {
            &_item {
              border-color: #f4f4f4;
            }
          }
        }
      }
    }
    #ContBox06 {
      padding: 40px 0;
      .contBox {
        &_title {
          margin-bottom: 17px;
          h3 { margin-top: 6px; }
        }
        &_cont {
          border: 1px solid #90caa1;
          padding: 30px 29px 39px;
          figure {
            text-align: center;
            margin-bottom: 40px;
            img {
              max-width: 241px;
              transform: translateX(-15px);
            }
          }
          .voiceBox {
            dl {
              display: flex;
              dt {
                width: 60px;
                .women { transform: translateY(-3px); }
              }
              dd {
                width: calc(100% - 60px);
                background-color: #0d7c3a;
                color: #fff;
                letter-spacing: 0.025em;
                line-height: 24px;
                text-align: justify;
                padding: 14px 15px;
                position: relative;
                .orange {
                  font-weight: bold;
                  color: #ffab40;
                }
                &:before {
                  position: absolute;
                  content: '';
                  border-top: 6px solid transparent;
                  border-bottom: 6px solid transparent;
                  border-right: 11px solid #0d7c3a;
                  top: 20px;
                  left: -10px;
                }
              }
              & + dl { margin-top: 30px; }
              &.right {
                flex-flow: row-reverse;
                dt { text-align: right; }
                dd {
                  &:before {
                    left: unset;
                    right: -10px;
                    border-left: 11px solid #0d7c3a;
                    border-right-width: 0;
                  }
                }
              }
            }
          }
        }
      }
    }
    #ContBox07 {
      background: url(../img/contents/bg_03.jpg) left top;
      background-size: 24px auto;
      padding: 40px 0;
      .contBox {
        &_title {
          margin-bottom: 17px;
          .en {
            background-image: url(../img/contents/sound/icon_03.png);
            color: #ffff00;
            margin-bottom: 7px;
          }
          h3 { color: #fff; }
        }
      }
      .contSubBox {
        background-color: #fff;
        box-shadow: 0 2px 8px rgba(0,0,0,0.15);
        .click {
          padding: 17px 15px 16px;
          background: url(../img/contents/icon_06.png) no-repeat right 15px center;
          background-size: 16px auto;
          position: relative;
          p {
            font-size: 16px;
            font-weight: bold;
            color: #0d7c3a;
            letter-spacing: 0.05em;
            line-height: 27px;
          }
          &.active {
            background-image: url(../img/contents/icon_07.png);
          }
        }
        .showBox {
          position: relative;
          padding: 35px 20px 30px;
          .stepBox {
            .cont {
              padding-top: 25px;
              h4 {
                font-size: 18px;
                font-weight: bold;
                color: #0d7c3a;
                letter-spacing: 0.05em;
                small {
                  font-size: 14px;
                  letter-spacing: 0;
                }
              }
              figure {
                margin: 14px 0 8px;
                img { max-width: 520px; }
              }
            }
            &:first-of-type { margin-top: 0; }
            &:last-of-type { margin-top: 33px; }
          }
          &:before {
            position: absolute;
            content: '';
            width: calc(100% - 30px);
            height: 1px;
            background-color: #f4f4f4;
            left: 15px;
            top: -1px;
          }
        }
      }
      .contSubBox01 {
        margin-bottom: 15px;
        .showBox {
          &:nth-of-type(2) .cont { padding-top: 26px; }
        }
      }
    }
    #ContBox08 {
      background-color: #f6f6f6;
      padding: 40px 0 50px;
      .contBox {
        &_title {
          margin-bottom: 34px;
          h3 {
            line-height: 37px;
            margin-top: 11px;
          }
        }
      }
      .contSubBox {
        background-color: #fff;
        box-shadow: 0 2px 8px rgba(0,0,0,0.15);
        padding: 17px 30px 22px;
        .title {
          color: #d71933;
          margin-bottom: 15px;
          h4 {
            font-size: 18px;
            font-weight: bold;
            line-height: 26px;
          }
          span {
            display: block;
            font-size: 12px;
            line-height: 20px;
          }
        }
        .cont {
          figure {
            margin-bottom: 12px;
            img { max-width: 285px; }
          }
        }
        & + .contSubBox { margin-top: 10px; }
      }
    }
    #ContBox09 {
      background: url(../img/contents/bg_03.jpg) left top;
      background-size: 24px auto;
      padding: 40px 0;
      .contBox {
        &_title {
          margin-bottom: 16px;
          .en {
            background-image: url(../img/contents/sound/icon_03.png);
            color: #ffff00;
            margin-bottom: 9px;
          }
          h3 { color: #fff; }
        }
      }
      .contSubBox {
        background-color: #fff;
        box-shadow: 0 2px 8px rgba(0,0,0,0.15);
        padding: 26px 20px 24px;
        h4 {
          font-size: 19px;
          font-weight: bold;
          color: #0d7c3a;
          letter-spacing: 0.05em;
          padding-left: 0.05em;
          text-align: center;
          margin-bottom: 7px;
        }
      }
      .contSubBox02 {
        padding-bottom: 0;
        margin-top: 20px;
        h4 { margin-bottom: 6px; }
        .form {
          &Wrap { margin-top: 22px; }
          &_cont {
            tr {
              border-color: #f4f4f4;
              &:first-of-type td { padding-bottom: 19px; }
            }
            th {
              font-weight: bold;
              color: #0d7c3a;
              padding-top: 17px;
              &:before { background-color: #ff9d00; }
            }
            td {
              margin-top: 8px;
              padding-bottom: 20px;
              .radio {
                span {
                  background-image: url(../img/contents/radio_05.png);
                  &.checked { background-image: url(../img/contents/radio_06.png); }
                }
              }
              label + label { margin-top: 8px; }
              input,textarea { border-color: #e6e6e6; }
            }
          }
          &_button {
            background-color: #ff9d00;
            margin-top: 5px;
          }
        }
      }
    }
  }
}

/*
サウンドクリエーター 確認画面
-------------------------------------*/
#PageSound.pageConfirm,
#PageCnSound.pageConfirm {
  #Main {
    .form {
      &_cont {
        tr {
          border-color: #f4f4f4;
        }
        th {
          font-weight: bold;
          color: #0d7c3a;
          &:before { background-color: #ff9d00; }
        }
      }
      &_button { background-color: #ff9d00; }
      &_return { color: #0d7c3a; }
    }
  }
}

/*
サウンドクリエーター 完了画面
-------------------------------------*/
#PageSound.pageThanks,
#PageCnSound.pageThanks {
  #Main {
    .homeBtn { background-color: #ff9d00; }
  }
}

/*
ピアノ出張レッスン
-------------------------------------*/
#PagePiano,
#PageCnPiano {
  #Footer {
    background-color: #41b2d1;
    .footer {
      &_box { border-color: #63cdea; }
      &_box01 .banner {
        background-color: #ace7f7;
        border-radius: 8px;
        .jp { color: #085970; }
        .en {
          color: #a2dcec;
          opacity: 1;
        }
      }
      &_box02 {
        .logo {
          span { color: #fff; }
        }
        small, a { color: #a5ebfe; }
      }
      &_copy small { color: #a5ebfe; }
    }
  }
  #FooterInfo {
    background-color: #024b60;
    .telBox {
      background-image: url(../img/contents/piano/telicon.png);
      span { opacity: 0.5; }
    }
    .formBox a {
      background-image: url(../img/contents/arrow_03.png);
      color: #024456;
      border-radius: 5px;
    }
  }
}
#PagePiano.pageIndex,
#PageCnPiano.pageIndex {
  #MainImg {
    .mainImg_cont {
      background-image: url(../img/contents/piano/mainimg.jpg);
      .title {
        position: absolute;
        width: 100%;
        color: #fff;
        text-align: center;
        bottom: 22px;
        span { display: inline-block; }
        .jp {
          font-size: 21px;
          letter-spacing: 0.1em;
          padding-left: 0.1em;
          margin-bottom: 17px;
          position: relative;
          &:before, &:after {
            position: absolute;
            content: '';
            width: 23px;
            height: 24px;
            background: no-repeat center;
            background-size: 100% auto;
            top: 2px;
          }
          &:before {
            background-image: url(../img/contents/piano/illus_02.png);
            left: -34px;
          }
          &:after {
            background-image: url(../img/contents/piano/illus_03.png);
            right: -28px;
          }
        }
        h2 {
          span {
            width: 59px;
            height: 59px;
            font-size: 36px;
            letter-spacing: 0;
            line-height: 59px;
            border-radius: 8px;
            & + span { margin-left: 5px; }
            &:nth-of-type(1), &:nth-of-type(5) { background-color: #fb6783; }
            &:nth-of-type(2) { background-color: #52bedc; }
            &:nth-of-type(3) { background-color: #ffd40d; }
            &:nth-of-type(4) { background-color: #42b581; }
          }
        }
        .en {
          font-size: 26px;
          font-weight: 300;
          letter-spacing: 0.5em;
          padding-left: 0.5em;
          margin-top: 12px;
        }
      }
    }
  }
  #Main {
    .contBox {
      &_title {
        text-align: center;
        position: relative;
        .en {
          display: block;
          font-size: 10px;
          text-transform: uppercase;
          letter-spacing: 0.3em;
          line-height: 16px;
          padding-left: 0.3em;
          margin-bottom: 2px;
        }
        .icon {
          position: absolute;
          width: 100%;
          max-width: 290px;
          height: 100%;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          &:before, &:after {
            position: absolute;
            content: '';
            background-repeat: no-repeat;
            background-position: left top;
            background-size: 100% auto;
          }
        }
        h3 {
          font-size: 26px;
          letter-spacing: 0.2em;
          line-height: 36px;
          padding-left: 0.2em;
        }
      }
      &_cont {
        figure {
          text-align: center;
          img { width: 100%; }
        }
      }
      .party {
        .icon {
          &:before {
            width: 74px;
            height: 62px;
            background-image: url(../img/contents/humasic/illus_01.png);
            bottom: 0;
          }
          &:after {
            width: 74px;
            height: 62px;
            background-image: url(../img/contents/humasic/illus_01.png);
            transform: scale(-1,1);
            bottom: -1px;
          }
        }
      }
      .icon-white {
        .icon {
          &:before {
            width: 18px;
            height: 19px;
            background-image: url(../img/contents/piano/illus_01.png);
            top: -4px;
          }
          &:after {
            width: 18px;
            height: 19px;
            background-image: url(../img/contents/piano/illus_01.png);
            transform: scale(-1,1);
            top: -4px;
          }
        }
      }
      .icon-color {
        .icon {
          &:before {
            width: 18px;
            height: 19px;
            background-image: url(../img/contents/piano/illus_02.png);
            top: -4px;
          }
          &:after {
            width: 18px;
            height: 19px;
            background-image: url(../img/contents/piano/illus_03.png);
            top: -4px;
          }
        }
      }
      .note {
        font-size: 12px;
        color: #888;
        line-height: 24px;
      }
    }

    #ContBox01 {
      padding-top: 36px;
      padding-bottom: 40px;
      .contBox {
        &_title {
          color: #0ab6e6;
          margin-bottom: 25px;
          .icon {
            &:before { left: 3px; }
            &:after { right: 9px; }
          }
        }
        &_cont {
          dl {
            dt {
              height: 41px;
              background: #fb6783 url(../img/contents/icon_04.png) no-repeat center;
              background-size: 21px auto;
              border-radius: 8px 8px 0 0;
            }
            dd {
              background-color: #f6f6f6;
              border-radius: 0 0 8px 8px;
              padding: 15px 21px 14px;
              h4 {
                font-size: 19px;
                font-weight: bold;
                color: #42b581;
                letter-spacing: 0.05em;
                text-align: center;
              }
              .note { margin-top: 3px; }
            }
            & + dl { margin-top: 5px; }
          }
        }
      }
    }
    #ContBox02 {
      background: url(../img/contents/piano/bg_01.jpg) left top;
      background-size: 99px auto;
      padding-top: 36px;
      padding-bottom: 40px;
      .contBox {
        &_title {
          color: #fff;
          margin-bottom: 23px;
          .icon {
            &:before { left: 83px; }
            &:after { right: 83px;}
          }
          .en { margin-bottom: 4px; }
        }
        &_cont {
          dl {
            background-color: #fff;
            border-radius: 8px;
            box-shadow: 0 2px 8px rgba(0,0,0,0.15);
            dt {
              font-size: 19px;
              color: #fff;
              letter-spacing: 0.05em;
              padding: 17px 20px 15px;
              border-radius: 8px 8px 0 0;
            }
            dd {
              padding: 12px 20px;
              span {
                font-weight: bold;
                &.pink { color: #ff7f97; }
                &.blue { color: #52bedc; }
                &.yellow { color: #f7ce0f; }
                &.green { color: #42b581; }
              }
            }
            &:nth-of-type(1) {
              dt {
                color: #fb6783;
                text-align: center;
                padding: 20px 20px 11px;
              }
              dd {
                padding: 0 20px 22px;
                ul li {
                  &:before {
                    width: 7px;
                    height: 7px;
                    top: 11px;
                  }
                  &:nth-of-type(2n) {
                    span { color: #52bedc; }
                    &:before { background-color: #52bedc; }
                  }
                  &:nth-of-type(2n+1) {
                    span { color: #f7ce04; }
                    &:before { background-color: #f7ce0f; }
                  }
                  &:nth-of-type(4n+1) {
                    span { color: #ff7f97; }
                    &:before { background-color: #ff7f97; }
                  }
                  &:nth-of-type(4n) {
                    span { color: #42b581; }
                    &:before { background-color: #42b581; }
                  }
                }
              }
            }
            &:nth-of-type(2) {
              dt { background-color: #ff7f97; }
            }
            &:nth-of-type(3) {
              dt { background-color: #52bedc; }
            }
            &:nth-of-type(4) {
              dt {
                background-color: #f7ce0f;
                padding-bottom: 17px;
              }
            }
            &:nth-of-type(5) {
              dt { background-color: #42b581; }
            }
            & + dl { margin-top: 20px; }
          }
        }
      }
    }
    #ContBox03 {
      padding-top: 45px;
      padding-bottom: 40px;
      .contBox {
        &_title {
          color: #fb6783;
          margin-bottom: 24px;
          .icon {
            &:before { left: 90px; }
            &:after { right: 90px; }
          }
          .en { margin-bottom: 5px; }
        }
        &_cont {
          table {
            caption {
              background-color: #fb6783;
              border-radius: 8px 8px 0 0;
              font-size: 18px;
              font-weight: bold;
              color: #fff;
              letter-spacing: 0.05em;
              text-align: left;
              padding: 13px 18px 9px;
            }
            tr {
              border-bottom: 1px solid #fff;
              &:nth-of-type(4) {
                td {
                  margin-top: 0;
                  padding-top: 2px;
                  .note {
                    font-size: 14px;
                    line-height: 28px;
                  }
                }
              }
              &:nth-of-type(5) {
                td {
                  margin-top: 0;
                  padding-top: 3px;
                }
              }
              &:last-of-type {
                border-bottom-width: 0;
                td {
                  border-radius: 0 0 8px 8px;
                  margin-top: 0;
                  padding-top: 3px;
                  padding-bottom: 10px;
                }
              }
            }
            th {
              display: block;
              background-color: #f6f6f6;
              color: #fb6783;
              line-height: 20px;
              padding: 12px 20px 0;
            }
            td {
              display: block;
              background-color: #f6f6f6;
              line-height: 28px;
              text-align: justify;
              padding: 0 20px 7px;
              margin-top: -2px;
              small {
                font-size: 12px;
                color: #888;
                letter-spacing: 0;
              }
            }
          }
        }
      }
    }
    #ContBox04 {
      background: url(../img/contents/piano/bg_02.jpg) left top;
      background-size: 99px auto;
      padding: 30px 0;
      .contBox {
        &_wrap {
          background-color: #fff;
          padding: 25px 30px 23px;
          border-radius: 8px;
          box-shadow: 0 2px 8px rgba(0,0,0,0.15);
        }
        &_title {
          color: #0ab6e6;
          margin-bottom: 21px;
          .icon {
            &:before { left: 3px; }
            &:after { right: 0; }
          }
          .en {
            padding-left: 0.6em;
          }
        }
        &_cont {
          h4 {
            font-size: 19px;
            font-weight: bold;
            color: #42b581;
            letter-spacing: 0.05em;
            line-height: 32px;
            padding-left: 31px;
            background: url(../img/contents/piano/icon_01.png) no-repeat left top 5px;
            background-size: 21px auto;
            margin-bottom: 8px;
          }
        }
      }
    }
    #ContBox05 {
      padding-top: 45px;
      .contBox {
        &_title {
          color: #f7ce0f;
          margin-bottom: 26px;
          .icon {
            &:before { left: 82px; }
            &:after { right: 82px; }
          }
          .en { margin-bottom: 6px; }
        }
        &_cont {
          background: url(../img/contents/piano/bg_03.jpg) left top;
          background-size: 99px auto;
          padding: 40px 0 68px;
        }
      }
      .contSubBox {
        background-color: #fff;
        border-radius: 8px;
        box-shadow: 0 2px 8px rgba(0,0,0,0.15);
        padding: 15px 30px 30px;
        .title {
          color: #fb6783;
          margin-bottom: 13px;
          h4 {
            font-size: 18px;
            margin-bottom: 1px;
          }
          span {
            display: block;
            font-size: 12px;
            line-height: 24px;
          }
        }
        .cont {
          p { margin: 17px 0 12px; }
          figure {
            img { max-width: 285px; }
          }
          .message {
            background-color: #f8f8f8;
            padding: 15px 15px 20px;
            h5 {
              font-size: 16px;
              font-weight: bold;
              color: #42b581;
            }
            p {
              font-size: 12px;
              color: #888;
              letter-spacing: 0.025em;
              line-height: 21px;
              margin: 8px 0 0;
            }
          }
        }
        & + .contSubBox { margin-top: 10px; }
      }
      .contSubBox02 {
        .title {
          h4 { margin-bottom: 3px; }
        }
        .cont {
          & > p { margin-top: 12px; }
          .message { padding-bottom: 14px; }
        }
      }
      .contSubBox03 {
        .title {
          margin-bottom: 14px;
          h4 { margin-bottom: 2px; }
        }
        .cont {
          .message { padding-bottom: 15px; }
        }
      }
      .contSubBox04 {
        .title {
          margin-bottom: 15px;
          h4 { margin-bottom: 2px; }
        }
        .cont {
          & > p { margin-top: 12px; }
          .message { padding-bottom: 13px; }
        }
      }
      .contSubBox05 {
        .title {
          margin-bottom: 14px;
          h4 { margin-bottom: 2px; }
        }
        .cont {
          & > p { margin: 12px 0 13px; }
          .message {
            padding-bottom: 16px;
            p {
              padding: 0 5px;
              margin-top: 6px;
            }
          }
        }
      }
    }
    #ContBox06 {
      padding-top: 45px;
      .contBox {
        &_title {
          color: #42b581;
          margin-bottom: 24px;
          .icon {
            &:before { left: 96px; }
            &:after { right: 96px; }
          }
          .en { margin-bottom: 7px; }
          h3 { font-size: 28px; }
        }
        &_cont {
          background: url(../img/contents/piano/bg_04.jpg) left top;
          background-size: 99px auto;
          padding: 41px 0 40px;
        }
      }
      .contSubBox {
        background-color: #fff;
        border-radius: 8px;
        box-shadow: 0 2px 8px rgba(0,0,0,0.15);
        padding: 0 30px 23px;
        .title {
          padding: 31px 0 18px 40px;
          background-repeat: no-repeat;
          background-position: left center;
          border-bottom: 1px solid #f4f4f4;
          margin-bottom: 16px;
          h4 {
            font-size: 19px;
            font-weight: bold;
            color: #fb6783;
          }
          &.female {
            background-image: url(../img/contents/piano/icon_02.png);
            background-size: 28px auto;
          }
          &.man {
            background-image: url(../img/contents/piano/icon_03.png);
            background-size: 29px auto;
          }
        }
        .cont {
          span {
            font-weight: bold;
            color: #42b581;
          }
        }
        & + .contSubBox { margin-top: 20px; }
      }
    }
    #ContBox07 {
      background-color: #f4f4f4;
      padding-top: 45px;
      padding-bottom: 50px;
      .contBox {
        &_title {
          color: #52bedc;
          margin-bottom: 26px;
          .icon {
            &:before { left: 103px; }
            &:after { right: 103px; }
          }
          .en { margin-bottom: 5px; }
          h3 {
            font-size: 28px;
            letter-spacing: 0.1em;
            padding-left: 0.1em;
          }
        }
        &_cont {
          table {
            background-color: #fff;
            border-radius: 8px;
            box-shadow: 0 2px 8px rgba(0,0,0,0.15);
            & + table { margin-top: 20px; }
            tr {
              &:nth-of-type(1) {
                th {
                  background-color: #52bedc;
                  border-top-left-radius: 8px;
                }
                td {
                  font-weight: bold;
                  position: relative;
                  &:after {
                    position: absolute;
                    content: '';
                    width: 100%;
                    height: 1px;
                    background-color: #f4f4f4;
                    left: 0;
                    bottom: 0;
                    transform: translateX(0.5px);
                  }
                }
              }
              &:nth-of-type(2) th {
                background-color: #f7ce0f;
                border-bottom-left-radius: 8px;
              }
            }
            th {
              width: 40px;
              font-size: 24px;
              font-weight: 500;
              color: #fff;
              letter-spacing: 0;
              text-align: center;
              vertical-align: middle;
            }
            td {
              letter-spacing: 0.05em;
              line-height: 24px;
              text-align: justify;
              padding: 14px 15px;
            }
          }
        }
      }
    }
    #ContBox08 {
      background-color: #fb6783;
      padding-top: 45px;
      padding-bottom: 50px;
      .contBox {
        &_title {
          color: #fff;
          margin-bottom: 25px;
          .icon {
            &:before { left: 71px; }
            &:after { right: 71px; }
          }
          .en { margin-bottom: 10px; }
          h3 {
            letter-spacing: 0.1em;
            padding-left: 0.1em;
          }
        }
        &_cont {
          .form {
            background-color: #fff;
            border-radius: 8px;
            box-shadow: 0 2px 8px rgba(0,0,0,0.15);
            padding: 0 20px 30px;
            &_cont {
              tr {
                border-color: #f4f4f4;
                &:first-of-type {
                  border-top-width: 0;
                  th { padding-top: 16px; }
                  td {
                    margin-top: 7px;
                    padding-bottom: 18px;
                  }
                }
                &:nth-of-type(2), &:nth-of-type(3) {
                  td { padding-bottom: 15px; }
                }
              }
              th {
                font-weight: bold;
                color: #fb6783;
                padding-top: 16px;
                &:before {
                  background-color: #42b581;
                  border-radius: 2px;
                }
              }
              td {
                margin-top: 9px;
                input, textarea {
                  border-color: #e6e6e6;
                  border-radius: 6px !important;
                }
                label {
                  font-size: 16px;
                  & + label { margin-top: 8px; }
                }
                .radio {
                  span {
                    background-image: url(../img/contents/radio_05.png);
                    &.checked { background-image: url(../img/contents/radio_07.png); }
                  }
                }
              }
            }
            &_button {
              background-color: #42b581;
              border-radius: 8px !important;
              margin-top: 5px;
            }
          }
        }
      }
    }
  }
}

/*
ピアノ出張レッスン 確認画面
-------------------------------------*/
#PagePiano.pageConfirm,
#PageCnPiano.pageConfirm {
  #Main {
    .form {
      &_cont {
        tr {
          border-color: #f4f4f4;
        }
        th {
          font-weight: bold;
          color: #fb6783;
          &:before {
            background-color: #42b581;
            border-radius: 2px;
          }
        }
      }
      &_button {
        background-color: #42b581;
        border-radius: 8px !important;
      }
      &_return { color: #fb6783; }
    }
  }
}

/*
ピアノ出張レッスン 完了画面
-------------------------------------*/
#PagePiano.pageThanks,
#PageCnPiano.pageThanks {
  #Main {
    .homeBtn {
      background-color: #42b581;
      border-radius: 8px;
    }
  }
}

/*
コンサルティングサービス
-------------------------------------*/
#PageConsulting,
#PageCnConsulting {
  #Footer {
    background-color: #142744;
    .footer {
      &_box { border-color: #30486d; }
      &_box01 {
        .banner {
          background-color: #b5cae9;
          .jp { color: #142744; }
          .en {
            font-weight: 900;
            color: #a5badb;
            opacity: 1;
          }
        }
      }
      &_box02, &_copy {
        small, a { color: #385786; }
      }
    }
  }
  #FooterInfo {
    background-color: #0c1c35;
    .telBox {
      background-image: url(../img/contents/consulting/telicon.png);
      span { opacity: 0.5; }
    }
    .formBox {
      a {
        background-color: url(../img/contents/arrow_04.png);
        color: #0c1c35;
      }
    }
  }
}
#PageConsulting.pageIndex,
#PageCnConsulting.pageIndex {
  #MainImg {
    .mainImg_cont {
      background-image: url(../img/contents/consulting/mainimg.jpg);
      .title {
        position: absolute;
        left: 50%;
        bottom: -50px;
        transform: translateX(-50%);
        width: calc(100% - 30px);
        max-width: 1000px;
        background-color: #fff;
        color: #284d89;
        padding: 26px 0 23px;
        span {
          display: block;
          letter-spacing: 0.15em;
          line-height: 21px;
          padding-left: 0.15em;
          &.en {
            font-size: 10px;
            color: #daa421;
            letter-spacing: 0.3em;
            text-transform: uppercase;
            padding-left: 0.3em;
          }
        }
        h2 {
          font-size: 26px;
          letter-spacing: 0;
          line-height: 31px;
          margin: 10px 0 5px;
        }
      }
    }
  }
  #Main {
    .contBox {
      &_title {
        text-align: center;
        margin-bottom: 24px;
        .en {
          display: block;
          font-size: 12px;
          color: #daa421;
          letter-spacing: 0.3em;
          text-transform: uppercase;
          padding-left: 0.3em;
          margin-bottom: -1px;
        }
        h3 {
          font-size: 27px;
          color: #142744;
          letter-spacing: 0.2em;
          line-height: 38px;
          padding-left: 0.2em;
        }
      }
      &_cont {
        small.gray {
          font-size: 12px;
          color: #888888;
          letter-spacing: 0;
        }
      }
    }

    #ContBox01 {
      background-color: #142744;
      padding-top: 121px;
      padding-bottom: 40px;
      .contBox {
        &_title {
          margin-bottom: 33px;
          position: relative;
          .en { margin-bottom: 7px; }
          h3 { color: #fff; }
          &:before {
            position: absolute;
            content: '';
            width: 1px;
            height: 40px;
            background-color: #31496d;
            top: -50px;
            left: 50%;
            transform: translateX(-50%);
          }
        }
        &_cont {
          background-color: #fff;
          box-shadow: 0 2px 8px rgba(0,0,0,0.15);
          padding: 25px 20px 22px;
          p {
            &:first-of-type {
              font-size: 16px;
              font-weight: bold;
              color: #284d89;
            }
            & + p { margin-top: 10px; }
          }
        }
      }
    }
    #ContBox02 {
      background: url(../img/contents/bg_04.jpg) left top;
      background-size: 24px auto;
      padding-top: 31px;
      padding-bottom: 40px;
      .contBox {
        &_title { margin-bottom: 23px; }
        &_cont {
          dl {
            background-color: #fff;
            box-shadow: 0 2px 8px rgba(0,0,0,0.15);
            dt {
              background-color: #142744;
              padding: 17px 30px 15px;
              position: relative;
              h4 {
                font-size: 19px;
                color: #fff;
                letter-spacing: 0.1em;
              }
              span {
                position: absolute;
                font-size: 24px;
                font-weight: 900;
                color: #1a3051;
                letter-spacing: 0;
                line-height: 1;
                text-transform: uppercase;
                right: 0;
                bottom: -2px;
              }
            }
            dd {
              padding: 12px 20px 12px;
              ul li:before { background-color: #daa421; }
            }
            & + dl { margin-top: 20px; }
            &:first-of-type, &:nth-of-type(3) {
              dd { padding-bottom: 11px; }
            }
          }
        }
      }
    }
    #ContBox03 {
      padding-top: 31px;
      padding-bottom: 40px;
      .contBox {
        &_cont {
          .box {
            background-color: #f6f6f6;
            padding: 20px 20px 14px;
            margin-bottom: 1px;
            h4 {
              font-size: 16px;
              font-weight: bold;
              color: #284d89;
              letter-spacing: 0.05em;
              line-height: 20px;
              margin-bottom: 5px;
            }
            p { line-height: 24px; }
            &:nth-of-type(2), &:nth-of-type(5) { padding-bottom: 13px; }
            &:nth-of-type(3) { padding-bottom: 12px; }
          }
        }
      }
    }
    #ContBox04 {
      background-color: #142744;
      padding-top: 29px;
      padding-bottom: 40px;
      .contBox {
        &_title {
          h3 { color: #fff; }
        }
      }
      .contSubBox {
        background-color: #fff;
        box-shadow: 0 2px 8px rgba(0,0,0,0.15);
        padding: 14px 30px 27px;
        .title {
          color: #284d89;
          margin-bottom: 18px;
          h4 {
            font-size: 18px;
            letter-spacing: 0.05em;
            margin-bottom: 1px;
          }
          span {
            display: block;
            font-size: 12px;
            letter-spacing: 0;
            line-height: 16px;
          }
        }
        .cont {
          & > p { margin: 11px 0 0; }
          figure {
            text-align: center;
            img { max-width: 285px; }
          }
          .subBox {
            margin-top: 16px;
            h5 {
              font-weight: bold;
              color: #284d89;
              padding-left: 15px;
              margin-bottom: 4px;
              position: relative;
              &:before {
                position: absolute;
                content: '';
                width: 4px;
                height: 14px;
                background-color: #284d89;
                top: 7px;
                left: 1px;
              }
            }
            p {
              line-height: 21px;
              a { color: #284d89; }
            }
          }
        }
      }
    }
    #ContBox05 {
      background-color: #f8f8f8;
      padding: 40px 0;
      .contBox {
        &_title {
          h3 {
            letter-spacing: 0.1em;
            padding-left: 0;
          }
        }
        &_cont {
          table {
            background-color: #fff;
            box-shadow: 0 2px 8px rgba(0,0,0,0.15);
            tr {
              &:nth-of-type(1) {
                th { background-color: #142744; }
                td {
                  font-weight: bold;
                  position: relative;
                  padding-bottom: 16px;
                  &:after {
                    position: absolute;
                    content: '';
                    width: 100%;
                    height: 1px;
                    background-color: #eeeeee;
                    left: 0;
                    bottom: 0;
                    transform: translateY(0.5px);
                  }
                }
              }
              &:nth-of-type(2) {
                th { background-color: #284d89; }
              }
            }
            th {
              width: 40px;
              font-size: 24px;
              font-weight: 500;
              color: #fff;
              letter-spacing: 0;
              text-align: center;
              vertical-align: middle;
            }
            td {
              text-align: justify;
              line-height: 24px;
              letter-spacing: 0.05em;
              padding: 14px 15px;
              ol {
                counter-reset: number;
                li {
                  list-style: none;
                  padding-left: 1.05em;
                  position: relative;
                  &:before {
                    position: absolute;
                    counter-increment: number;
                    content: counter(number);
                    width: 12px;
                    height: 12px;
                    font-size: 85%;
                    color: #284d89;
                    letter-spacing: 0;
                    line-height: 12px;
                    text-align: center;
                    border-radius: 50%;
                    border: 1px solid #284d89;
                    top: 5px;
                    left: 0;
                  }
                }
              }
            }
            & + table { margin-top: 20px; }
            &:nth-last-of-type(2) tr:last-of-type {
              td { letter-spacing: 0.025em; }
            }
            &:last-of-type tr:first-of-type {
              td { letter-spacing: 0.04em; }
            }
          }
        }
      }
    }
    #ContBox06 {
      background-color: #e6eefa;
      padding-top: 41px;
      padding-bottom: 20px;
      .contBox {
        &_title {
          margin-bottom: 25px;
          .en { margin-bottom: -2px; }
        }
        &_cont {
          .form {
            margin-top: 23px;
            &_cont {
              tr {
                border-color: #dae5f4;
                &:first-of-type {
                  td { margin-top: 8px; }
                }
                &:nth-of-type(2) {
                  th:before {
                    content: '任意';
                    background-color: #a4a4a4;
                  }
                }
                &:nth-of-type(3), &:nth-of-type(4) {
                  td { padding-bottom: 15px; }
                }
              }
              th {
                font-weight: bold;
                color: #284d89;
                padding-top: 16px;
                &:before { background-color: #daa421; }
              }
              td {
                margin-top: 9px;
                input, textarea { border-color: #dae4f4; }
                label { font-size: 16px; }
                .radio {
                  span {
                    background-image: url(../img/contents/radio_08.png);
                    &.checked { background-image: url(../img/contents/radio_09.png); }
                  }
                }
              }
            }
            &_button {
              background-color: #daa421;
              margin-top: 10px;
            }
          }
        }
      }
    }
  }
}

/*
コンサルティングサービス 確認画面
-------------------------------------*/
#PageConsulting.pageConfirm,
#PageCnConsulting.pageConfirm {
  #Main {
    .form {
      &_cont {
        tr {
          border-color: #dae5f4;
          &:nth-of-type(2) {
            th:before {
              content: '任意';
              background-color: #a4a4a4;
            }
          }
        }
        th {
          font-weight: bold;
          color: #284d89;
          &:before {
            background-color: #daa421;
            border-radius: 2px;
          }
        }
      }
      &_button { background-color: #daa421; }
      &_return { color: #284d89; }
    }
  }
}

/*
コンサルティングサービス 完了画面
-------------------------------------*/
#PageConsulting.pageThanks,
#PageCnConsulting.pageThanks {
  #Main {
    .homeBtn { background-color: #daa421; }
  }
}


/*########## 20210706 中国語サイト追加 ##########*/
/*
トップページ
-------------------------------------*/
#PageCn.pageIndex {
  #Main {
    position: relative;
    &:before {
      position: absolute;
      content: '';
      width: calc(100% - 30px);
      height: 50px;
      background-color: #fff;
      top: -35px;
      left: 0;
    }
    .contBox {
      &_title {
        .jp { font-family: 'Noto Serif SC', serif; }
      }
    }
    #ContBox01 { display: none; }
    #ContBox02 {
      position: relative;
      padding-top: 0;
      margin-top: -15px;
      .contBox {
        &_title {
          padding-left: 45px;
        }
      }
      .contSubBox01 {
        .contSubBox_title {
          h3 { letter-spacing: 0.025em; }
        }
      }
      .contSubBox04 {
        .contSubBox_title {
          h3, .serviceName {
            letter-spacing: 0;
          }
          span {
            top: -20px;
          }
        }
      }
    }
    #ContBox04 {
      .contSubBox {
        &_cont {
          table {
            tr:nth-of-type(4) {
              th { padding: 12px 15px 12px 0; }
              td { padding: 14px 0; }
            }
          }
        }
      }
    }
    #ContBox06 {
      .contBox {
        &_cont {
          .form {
            &_cont {
              tr:nth-of-type(2) {
                th:before { content: '任选项目'; }
              }
            }
          }
        }
      }
    }
  }
}

/*
トップページ 確認画面
-------------------------------------*/
#PageCnContact.pageConfirm {
  #Main {
    .form {
      tr:nth-of-type(2) {
        th:before {
          content: '任选项目';
          background-color: #a4a4a4;
        }
      }
    }
  }
}

/*
ヒューマジック
-------------------------------------*/
#PageCnHumasic.pageIndex {
  #Main {
    #ContBox01,
    #ContBox11 {
      .contBox_cont {
        dl dt {
          padding: 9px 15px 9px 51px;
        }
      }
    }
    #ContBox06 {
      .contBox_cont {
        table th { padding: 9px 20px; }
      }
    }
    #ContBox07 {
      .contBox {
        &_title {
          .icon {
            &:before, &:after {
              bottom: auto;
              top: 25px;
            }
            &:before {
              width: 32px;
              height: 30px;
              background-image: url(../img/contents/humasic/illus_03.png);
              left: 38px;
            }
            &:after {
              width: 29px;
              height: 22px;
              background-image: url(../img/contents/humasic/illus_04.png);
              transform: unset;
              top: 30px;
              right: 31px;
            }
          }
        }
      }
    }
    #ContBox09 {
      .contBox {
        &_title {
          .icon {
            &:before { left: 10px; }
            &:after { right: 10px; }
          }
        }
      }
    }
    #ContBox10 {
      .contBox {
        &_title {
          .icon {
            &:before { left: 55px; }
            &:after { right: 55px; }
          }
        }
        &_cont {
          .contSubBox {
            .cont .label { padding: 7px 0 7px 0.05em; }
          }
        }
      }
    }
    #ContBox12 {
      .contBox {
        &_title {
          .icon {
            &:before { left: 70px; }
            &:after { right: 70px; }
          }
        }
        &_cont {
          .form {
            &_cont {
              tr:nth-of-type(2) {
                th:before {
                  content: '任选项目';
                }
              }
            }
          }
        }
      }
    }
  }
}

/*
ヒューマジック 確認画面
-------------------------------------*/
#PageCnHumasic.pageConfirm {
  #Main {
    #ContBox01 {
      .contBox_cont {
        .form_cont {
          tr:nth-of-type(2) {
            th:before {
              content: '任选项目';
            }
          }
        }
      }
    }
  }
}

/*
サウンドクリエーター
-------------------------------------*/
#PageCnSound.pageIndex {
  #MainImg {
    .mainImg {
      &_cont {
        .title {
          width: 100%;
          h2 { font-weight: 500; }
          span {
            background-image: url(../img/contents/cn/sound/text_01.png);
            background-position: center center;
          }
        }
      }
    }
  }
  #Main {
    #ContBox01 {
      .contSubBox {
        &_cont {
          dl {
            dt {
              padding-top: 6px;
              padding-bottom: 7px;
            }
          }
        }
      }
    }
    #ContBox02 {
      .contBox {
        &_cont {
          .title {
            span { background-image: url(../img/contents/cn/sound/text_02.png); }
          }
        }
      }
    }
    #ContBox04 {
      .contBox {
        &_cont {
          .table {
            h4 {
              padding-top: 9px;
              padding-bottom: 9px;
            }
          }
        }
      }
    }
  }
}

/*
ピアノ出張レッスン
-------------------------------------*/
#PageCnPiano.pageIndex {
  #MainImg {
    .mainImg {
      &_cont {
        .title {
          h2 span {
            width: 50px;
            height: 50px;
            line-height: 48px;
            &:nth-of-type(6) {
              background-color: #52bedc;
            }
          }
        }
      }
    }
  }
  #Main {
    #ContBox03 {
      .contBox_cont {
        table caption {
          padding: 11px 18px;
        }
      }
    }
  }
}

/*
コンサルティングサービス
-------------------------------------*/
#PageCnConsulting.pageIndex {
  #Main {
    #ContBox06 {
      .contBox {
        &_cont {
          .form {
            &_cont {
              tr:nth-of-type(2) {
                th:before {
                  content: '任选项目';
                  background-color: #a4a4a4;
                }
              }
              tr:last-of-type {
                th:before {
                  background-color: #daa421;
                }
              }
            }
          }
        }
      }
    }
  }
}

/*
コンサルティングサービス 確認画面
-------------------------------------*/
#PageCnConsulting.pageConfirm {
  #Main {
    #ContBox01 {
      .contBox_cont {
        .form_cont {
          tr:nth-of-type(2) {
            th:before {
              content: '任选项目';
            }
          }
        }
      }
    }
  }
}




@media print, screen and (min-width: 850px) {
  /*
  トップページ
  -------------------------------------*/
  #Page.pageIndex,
  #PageCn.pageIndex {
    #MainImg {
      .mainImg_cont {
        background-image: url(../img/contents/top/mainimg_pc.jpg);
        padding-top: 52.109375%;
      }
    }
    #Main {
      #ContBox01 {
        &:before { top: -31px; }
      }
      #ContBox02 {
        .contBox {
          &_title {
            width: 1000px;
            padding: 0;
            margin: 0 auto 51px;
          }
        }
      }
      #ContBox03 {
        .contBox_title {
          width: 1000px;
          padding: 0;
          margin: 0 auto 25px;
        }
      }
      #ContBox04 {
        .contBox {
          &_title {
            width: 1000px;
            padding: 0;
            margin: 0 auto 24px;
          }
        }
      }
      #ContBox06 {
        overflow-x: hidden;
        .contBox {
          &_cont {
            .policy {
              &:before {
                width: 100vw;
                min-width: 1000px;
                left: calc( calc( 100% - 100vw ) / 2);
              }
              &.change_width {
                &:before { left: 0; }
              }
            }
          }
        }
      }
    }
  }

  /*
  ヒューマジック
  -------------------------------------*/
  #PageHumasic.pageIndex,
  #PageCnHumasic.pageIndex {
    #MainImg {
      .mainImg_cont {
        background-image: url(../img/contents/humasic/mainimg_pc.jpg);
      }
    }
    #Main {
      #ContBox03 {
        .contBox {
          &_cont {
            .imgBox {
              margin-bottom: 20px;
              &_cont {
                figure { pointer-events: none; }
                span { display: none; }
              }
            }
          }
        }
      }
    }
  }

  /*
  お知らせ
  -------------------------------------*/
  #PageInformation.pageIndex {
    #Main {
      .innerBasic { padding: 0; }
    }
  }

  /*
  お知らせ 記事詳細ページ
  -------------------------------------*/
  #PageInformation.pageEntry {
    #Main {
      .innerBasic { padding: 0; }
    }
  }

  /*
  サウンドクリエーター
  -------------------------------------*/
  #PageSound.pageIndex,
  #PageCnSound.pageIndex {
    #MainImg {
      .mainImg_cont { background-image: url(../img/contents/sound/mainimg_pc.jpg); }
    }
  }

  /*
  ピアノ出張レッスン
  -------------------------------------*/
  #PagePiano.pageIndex,
  #PageCnPiano.pageIndex {
    #MainImg {
      .mainImg_cont { background-image: url(../img/contents/piano/mainimg_pc.jpg); }
    }
  }

  /*
  コンサルティングサービス
  -------------------------------------*/
  #PageConsulting.pageIndex,
  #PageCnConsulting.pageIndex {
    #MainImg {
      .mainImg_cont { background-image: url(../img/contents/consulting/mainimg_pc.jpg); }
    }
  }
}


@media print, screen and (max-width: 360px) {
  /*
  サウンドクリエーター
  -------------------------------------*/
  #PageSound.pageIndex,
  #PageCnSound.pageIndex {
    #MainImg {
      .mainImg_cont .title {
        span { background-position: center; }
      }
    }
  }

  /*
  ピアノ出張レッスン
  -------------------------------------*/
  #PagePiano.pageIndex,
  #PageCnPiano.pageIndex {
    #Main {
      #ContBox04 {
        .contBox_title {
          .icon {
            &:before { left: -14px; }
            &:after { right: -11px; }
          }
        }
      }
    }
  }
}

@media print, screen and (max-width: 320px) {
  /*
  ヒューマジック
  -------------------------------------*/
  #PageHumasic.pageIndex
  #PageCnHumasic.pageIndex {
    #Main {
      .contBox_title {
        &.party .icon {
          &:before { left: 5px; }
          &:after { right: 5px; }
        }
        &.fouricon .icon {
          &:before { left: 26px; }
          &:after { right: 24px; }
        }
        &.twoicon .icon {
          &:before { left: 25px; }
          &:after { right: 24px; }
        }
      }
      #ContBox04 {
        .contBox_title {
          &.fouricon .icon {
            &:before { left: 36px; }
            &:after { right: 34px; }
          }
        }
      }
      #ContBox09 {
        .contBox_title {
          &.twoicon .icon {
            &:before { left: 0; }
            &:after { right: 0; }
          }
        }
      }
      #ContBox10 {
        .contBox_title {
          &.twoicon .icon {
            &:before { left: 15px; }
            &:after { right: 15px; }
          }
        }
      }
      #ContBox12 {
        .contBox_title {
          &.twoicon .icon {
            &:before { left: 14px; }
            &:after { right: 16px; }
          }
        }
      }
    }
  }

  /*
  サウンドクリエーター
  -------------------------------------*/
  #PageSound.pageIndex,
  #PageCnSound.pageIndex {
    #MainImg {
      .mainImg_cont .title {
        h2 { font-size: 28px; }
      }
    }
  }

  /*
  ピアノ出張レッスン
  -------------------------------------*/
  #PagePiano.pageIndex,
  #PageCnPiano.pageIndex {
    #Main {
      #ContBox04 {
        .contBox_title {
          .icon {
            &:before { left: -30px; }
            &:after { right: -30px; }
          }
        }
      }
    }
  }


  /*########## 20210706 中国語サイト追加 ##########*/
  /*
  ヒューマジック
  -------------------------------------*/
  #PageCnHumasic.pageIndex {
    #Main {
      #ContBox07 {
        .contBox_title {
          h3 {
            letter-spacing: 0.1em;
            padding-left: 0.1em;
          }
          .icon {
            &:before { left: 32px; }
          }
        }
      }
    }
  }

  /*
  ピアノ出張レッスン
  -------------------------------------*/
  #PageCnPiano.pageIndex {
    #MainImg {
      .mainImg_cont {
        .title {
          h2 span {
            width: 46px;
            height: 46px;
            font-size: 32px;
            line-height: 44px;
          }
        }
      }
    }
  }
}

@media print, screen and (min-width: 1050px) {
  #Page.pageIndex,
  #PageCn.pageIndex {
    #Main {
      #ContBox01 {
        .contBox {
          &_title, &_cont { padding: 0; }
        }
      }
    }
  }
}

// IE
_:-ms-lang(x), #PageSound.pageIndex #Main #ContBox06 .contBox_cont .voiceBox dl.right dd::before {
  left: auto;
}
_:-ms-lang(x), #PageCnSound.pageIndex #Main #ContBox06 .contBox_cont .voiceBox dl.right dd::before {
  left: auto;
}